<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else class="text-normal">
      <h4 class="mb-3 header-tablepage">
        {{ formHead() }}
      </h4>
      <b-row>
        <b-col sm="3" class="">
          <div class="header-secondary title-tabs header-title py-3">
            <b>Question List</b>
            <b-button class="add-btn" @click="addQuestion">
              <b-icon icon="plus-square" class="mr-2"></b-icon
              ><span>Create New</span></b-button
            >
          </div>
        </b-col>
        <b-col sm="9" class="">
          <div
            class="header-secondary title-tabs header-title d-none d-sm-flex py-3 align-items-center"
          >
            <b>Detail</b>
            <InputSelectLanguage
              ref="inputLang"
              @changeLang="changeLanguage"
              v-if="$route.params.id != 0"
            />
          </div>
        </b-col>
      </b-row>

      <b-row class="bg-grey">
        <b-col sm="3" class="">
          <template v-for="(field, index) of requiredField">
            <div
              class="p-2 question-panel shadow-sm overflow-auto"
              @click="handleClickActive(false, index)"
              :key="field + index"
            >
              <div
                :class="[
                  'mt-2 p-3 question-card pointer',
                  !activeAddition && activeIndex == index && 'active',
                ]"
              >
                <div class="d-flex align-items-center" style="flex-flow: wrap">
                  <span class="mr-2 text-black font-weight-bold"
                    >Required Field :</span
                  >
                  <div class="mr-2 type-box">{{ field }}</div>
                  <span style="word-break: break-all"></span>
                </div>
                <div class="mt-1">
                  <p class="mb-1 text-light-grey text-truncate">
                    {{ questionDetailText(index) }}
                  </p>
                </div>
              </div>
            </div>
          </template>

          <draggable :list="additionnalQuestion" @end="changeDrag">
            <div
              class="p-2 question-panel shadow-sm overflow-auto"
              v-for="(addQues, index) of additionnalQuestion"
              :key="'add-' + index"
              @click="handleClickActive(true, index)"
            >
              <div
                :class="[
                  'mt-2 p-3 question-card pointer',
                  activeAddition && index == activeIndex && 'active',
                ]"
              >
                <div class="d-flex align-items-center" style="flex-flow: wrap">
                  <span class="mr-2 text-black font-weight-bold"
                    >Question {{ index + 1 }} :</span
                  >
                  <div class="mr-2 type-box">
                    {{ questionTypeText(addQues.field_type_id) }}
                  </div>
                  <span style="word-break: break-all"></span>
                </div>
                <div class="mt-1">
                  <p class="mb-1 text-light-grey text-truncate">
                    {{
                      addQues.field_language.length > 0
                        ? addQues.field_language[0].name
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </div>
          </draggable>
        </b-col>
        <b-col sm="9" class="">
          <div class="header-secondary title-tabs d-block d-sm-none">
            <span>Detail</span>
          </div>
          <div class="pt-2 bg-white text-normal shadow-sm">
            <div
              v-if="!activeAddition && [0, 1, 2].includes(activeIndex)"
              :set="
                (customerField =
                  activeIndex == 0
                    ? questionList
                    : activeIndex == 1
                    ? addressList
                    : socialField)
              "
            >
              <b-row
                class="px-3"
                v-for="(profile, index) in customerField"
                :key="index"
              >
                <b-col sm="2">
                  <div v-if="index == 0" class="text-center font-bold">
                    Sort Order
                  </div>
                  <div class="content-between mt-3">
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      @click="
                        switchProfileIndex(profile, index, false, activeIndex)
                      "
                      :class="`icon-size ${
                        index == 0 || activeIndex == 1 ? 'disabled' : 'pointer'
                      }`"
                    />
                    {{ index + 1 }}
                    <font-awesome-icon
                      icon="chevron-circle-down"
                      @click="
                        switchProfileIndex(profile, index, true, activeIndex)
                      "
                      :class="`icon-size ${
                        index == questionList.length - 1 || activeIndex == 1
                          ? 'disabled'
                          : 'pointer'
                      }`"
                    />
                  </div>
                </b-col>
                <b-col sm="1" class="text-center">
                  <div v-if="index == 0" class="font-bold">Required</div>

                  <b-form-checkbox
                    class="disableCheckbox mt-3 mb-1"
                    :id="`checkbox-profile-${index}`"
                    :name="`checkbox-profile-${index}`"
                    v-model="profile.required"
                    :disabled="disabledField(profile)"
                    @input="handleCheckRequired($event, profile)"
                  >
                  </b-form-checkbox
                ></b-col>
                <b-col sm="2" :class="'flex-auto'">
                  <div v-if="index == 0" class="font-bold">Field Name</div>

                  <div
                    v-if="profile.field_profile_type_id == 2"
                    class="margin-input"
                  >
                    <multiselect
                      :close-on-select="false"
                      :clear-on-select="false"
                      v-model="value"
                      :options="profile.field_choices"
                      :preserve-search="true"
                      placeholder="คำนำหน้า"
                      label="name"
                      :allow-empty="true"
                      track-by="name"
                      :preselect-first="true"
                      deselectLabel="x"
                      select-label=""
                      :searchable="false"
                      selectedLabel="✓"
                      :multiple="true"
                      :disabled="!profile.is_show"
                      @input="addTag"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                        v-if="values.length &amp;&amp; !isOpen"
                        ><span class="multiselect__single"
                          >คำนำหน้า
                        </span></template
                      >
                      <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.name }}</template
                      >
                    </multiselect>
                  </div>
                  <div
                    v-else-if="profile.field_profile_type_id == 9"
                    class="margin-input"
                  >
                    <b-dropdown
                      class="dropdown-input-field"
                      text="วันเกิด"
                      ref="dropdown"
                      :disabled="!profile.is_show"
                      block
                    >
                      <template #button-content>
                        <div class="content-between">
                          วันเกิด
                          <font-awesome-icon icon="chevron-down" />
                        </div>
                      </template>
                      <b-dropdown-form
                        ><b>Maximun Age</b><br />
                        <InputText
                          textFloat=""
                          v-model="profile.field_conditions[0].value"
                          placeholder="Year"
                          class="margin-input"
                          type="number"
                        />
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                  <div v-else>
                    <InputText
                      v-if="!isLoading"
                      textFloat=""
                      disabled
                      v-model="profile.profile_name"
                      :placeholder="profile.profile_name"
                      class="margin-input"
                      type="text"
                    />
                  </div>
                </b-col>
                <b-col sm="2" :class="'flex-auto'">
                  <div v-if="index == 0" class="font-bold">Description</div>
                  <template v-if="activeIndex == 2">
                    <InputText
                      v-if="!isLoading"
                      textFloat=""
                      v-model="profile.name"
                      :placeholder="profile.name"
                      class="margin-input"
                      type="text"
                    />
                  </template>
                  <template v-else>
                    <InputText
                      v-if="!isLoading"
                      textFloat=""
                      v-model="profile.field_language[languageIndex].name"
                      :placeholder="profile.field_language[languageIndex].name"
                      class="margin-input"
                      type="text"
                      @input="(val) => (profile.name = val)"
                    />
                  </template>
                </b-col>

                <b-col sm="1" class="text-center">
                  <div v-if="index == 0" class="font-bold">Display</div>
                  <b-form-checkbox
                    class="disableCheckbox mt-3 mb-1"
                    :id="`checkbox-company-is-show-${index}`"
                    :name="`checkbox-company-is-show-${index}`"
                    v-model="profile.is_show"
                    :value="1"
                    :unchecked-value="0"
                    :disabled="profile.required || disabledField(profile)"
                  >
                  </b-form-checkbox>
                </b-col>
                <b-col sm="3" :class="'flex-auto'" class="text-center">
                  <div v-if="index == 0" class="font-bold">
                    Display in Edit Profile
                  </div>
                  <b-form-checkbox
                    class="disableCheckbox mt-3 mb-1"
                    :id="`checkbox-profile-edit-is-show-${index}`"
                    :name="`checkbox-profile-edit-is-show-${index}`"
                    v-model="profile.show_on_edit_profile"
                    :value="1"
                    :unchecked-value="0"
                    :disabled="profile.required || disabledField(profile)"
                  >
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
            <div v-else class="p-3">
              <div v-if="additionnalQuestion[activeIndex]">
                <b-form-checkbox
                  v-model="additionnalQuestion[activeIndex].is_show"
                  :checked="additionnalQuestion[activeIndex].is_show"
                  :value="1"
                  :unchecked-value="0"
                  @change="
                    $event == 0 &&
                    additionnalQuestion[activeIndex].show_on_edit_profile == 0
                      ? (additionnalQuestion[activeIndex].required = false)
                      : undefined
                  "
                  >Show on Register Form</b-form-checkbox
                >
              </div>
              <div class="content-between">
                <div v-if="additionnalQuestion[activeIndex]">
                  <b-form-checkbox
                    v-model="
                      additionnalQuestion[activeIndex].show_on_edit_profile
                    "
                    :checked="
                      additionnalQuestion[activeIndex].show_on_edit_profile
                    "
                    :value="1"
                    :unchecked-value="0"
                    @change="
                      $event == 0 &&
                      additionnalQuestion[activeIndex].is_show == 0
                        ? (additionnalQuestion[activeIndex].required = false)
                        : undefined
                    "
                    >Show on Edit Profile</b-form-checkbox
                  >
                </div>
                <div
                  class="d-flex justify-content-end align-items-center"
                  v-if="additionnalQuestion[activeIndex]"
                >
                  <label class="mb-0 mr-2">Required</label>
                  <b-form-checkbox
                    class="mb-1 mr-2"
                    size="lg"
                    v-model="additionnalQuestion[activeIndex].required"
                    :disabled="
                      typeHidden(
                        additionnalQuestion[activeIndex].field_type_id
                      ) ||
                      (!additionnalQuestion[activeIndex].is_show &&
                        !additionnalQuestion[activeIndex].show_on_edit_profile)
                    "
                    switch
                  >
                  </b-form-checkbox>
                  <b-icon
                    size="lg"
                    icon="trash-fill"
                    class="icon-size pointer"
                    @click="deleteQuestion"
                  />
                </div>
              </div>
              <div class="mt-3 w-25">
                <b-form-select
                  v-model="additionnalQuestion[activeIndex].field_type_id"
                  :options="fieldTypes"
                  @change="changeQeustionType"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </div>
              <div class="mt-3">
                <div
                  v-if="
                    !(
                      additionnalQuestion[activeIndex].field_type_id == 10 ||
                      additionnalQuestion[activeIndex].field_type_id == 11 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    )
                  "
                >
                  <InputText
                    class="w-50"
                    textFloat="Validate Text"
                    v-model="additionnalQuestion[activeIndex].validate_text"
                    :placeholder="
                      additionnalQuestion[activeIndex].validate_text
                    "
                    :disabled="
                      additionnalQuestion[activeIndex].field_type_id == 10 ||
                      additionnalQuestion[activeIndex].field_type_id == 11 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    "
                    type="text"
                  />
                </div>
                <div
                  v-if="
                    !(
                      additionnalQuestion[activeIndex].field_type_id == 9 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    )
                  "
                >
                  <InputText
                    isRequired
                    :class="[
                      ' question-name-input margin-input-5',
                      isSubmit &&
                      !additionnalQuestion[activeIndex].field_language[
                        languageIndex
                      ].name
                        ? 'error'
                        : '',
                    ]"
                    textFloat="Question"
                    v-model="
                      additionnalQuestion[activeIndex].field_language[
                        languageIndex
                      ].name
                    "
                    :disabled="
                      additionnalQuestion[activeIndex].field_type_id == 9 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    "
                    :placeholder="
                      additionnalQuestion[activeIndex].field_language[
                        languageIndex
                      ].name
                    "
                    type="text"
                    :v="
                      $v.additionnalQuestion.$each.$iter[activeIndex]
                        .field_language.$each.$iter[languageIndex].name
                    "
                    @input="
                      (val) => (additionnalQuestion[activeIndex].name = val)
                    "
                  />
                </div>
              </div>
              <div class="my-3">
                <FieldTextDetail
                  v-if="additionnalQuestion[activeIndex].field_type_id == 1"
                  :questionList="additionnalQuestion"
                  :questionActive="activeIndex"
                />
                <FieldChoiceDetail
                  v-if="
                    additionnalQuestion[activeIndex].field_type_id >= 2 &&
                    additionnalQuestion[activeIndex].field_type_id <= 4
                  "
                  :questionList="additionnalQuestion"
                  :questionActive="activeIndex"
                />
                <DateInput
                  v-if="additionnalQuestion[activeIndex].field_type_id == 5"
                />

                <ModalSelectBranch
                  v-if="
                    additionnalQuestion[activeIndex].branchList &&
                    additionnalQuestion[activeIndex].branchList.length > 0 &&
                    additionnalQuestion[activeIndex].field_type_id == 6
                  "
                  :list="additionnalQuestion[activeIndex].branchList"
                  @selectBranch="selectBranch"
                  :field="additionnalQuestion[activeIndex]"
                  text="Select Branch"
                  id=""
                  :BranchSelectList="
                    additionnalQuestion[activeIndex].select_branch
                  "
                />
                <Date
                  v-if="additionnalQuestion[activeIndex].field_type_id == 7"
                  class=""
                />

                <InputTextArea
                  v-if="additionnalQuestion[activeIndex].field_type_id == 12"
                  textFloat=""
                  type="text"
                  class="mt-3 custom-input"
                  placeholder=""
                  rows="3"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <FooterAction routePath="/setting/personal" @submit="sendForm(0)" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Date from "@/components/form/preview/Date";
import DateInput from "@/components/form/preview/DateInput";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import OtherLoading from "@/components/loading/OtherLoading";
import FieldChoiceDetail from "@/components/form/detail/FieldChoiceDetail";
import FieldTextDetail from "@/components/form/detail/FieldTextDetail";

import InputTextArea from "@/components/inputs/InputTextArea";
import Multiselect from "vue-multiselect";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import InputSelectLanguage from "@/components/inputs/InputSelectLanguage";
export default {
  props: {
    id: { required: true, type: Number },
  },
  components: {
    draggable,
    Date,
    DateInput,
    ModalSelectBranch,
    OtherLoading,
    FieldChoiceDetail,
    FieldTextDetail,
    InputSelectLanguage,
    InputTextArea,
    Multiselect,
  },
  validations() {
    return {
      additionnalQuestion: {
        $each: {
          field_language: {
            $each: {
              name: {
                required: requiredIf(function (item, val) {
                  return item.language_id == 1 &&
                    (item.field_type_id != 14 || item.field_type_id != 13)
                    ? true
                    : false;
                }),
              },
            },
          },
        },
      },
    };
  },
  data() {
    return {
      value: [],
      isLoading: true,
      isBusy: false,
      isSubmit: false,
      requiredField: [
        "Customer Information",
        "Address Information",
        "Social Information",
      ],
      questionList: [],
      socialField: [],
      addressList: [],
      activeIndex: 0,
      questionProfileIndex: null,
      showProfilePanel: true,
      additionnalQuestion: [],
      branchList: [],
      fieldTypes: [
        { id: 1, name: "Field" },
        { id: 2, name: "Checkbox" },
        { id: 3, name: "Multiple Choice" },
        { id: 4, name: "Dropdown" },
        { id: 5, name: "DateTime" },
        { id: 6, name: "Branch dropdown" },
        { id: 7, name: "Date" },
        { id: 8, name: "Image" },
        { id: 10, name: "Title" },
        { id: 11, name: "Subtitle" },
        { id: 12, name: "Text Area" },
        { id: 13, name: "Space" },
      ],
      activeAddition: false,
      activeIndex: 0,
      languageList: [],
      selectLanguage: "",
      languageIndex: 0,
    };
  },
  computed: {},

  async created() {
    this.getField();
    this.getFieldType();
    // this.getDisplayLang();
  },
  mounted() {},
  methods: {
    async getFieldType() {
      await this.$store.dispatch("getFieldTypes");
      let data = this.$store.state.form.fieldtype;
      if (data.result == 1) {
        this.fieldTypes = data.detail.fieldTypes.filter(
          (type) => type.id != 9 && type.id != 14
        );
      }
    },
    addTag(newTag) {
      let quesPrefix = this.questionList.find(
        (el) => el.field_profile_type_id == 2
      );
      quesPrefix.field_choices.map((el) => {
        if (newTag.findIndex((els) => el.id == els.id) != -1) {
          el.is_display = 1;
          el.check = 2;
        } else {
          el.is_display = 0;
        }
      });
    },
    async getField() {
      this.isLoading = true;
      let url = `/setting/getregisterform`;
      if (this.id == 2) url = `/setting/getcompanyform`;
      if (this.id == 3) url = `/setting/getregisterform/email`;
      const { data } = await this.axios(url, {
        headers: {
          "LANGUAGE-ID": this.selectLanguage || 1,
        },
      });
      let quesPrefix = data.detail.profileField.find(
        (el) => el.field_profile_type_id == 2
      );

      this.value =
        quesPrefix &&
        quesPrefix.field_choices.filter((el) => el.is_display == 1);
      data.detail.profileField.map((el) =>
        el.required ? (el.is_show = 1) : false
      );
      for (const field of data.detail.customField) {
        if (field.field_type_id === 6) {
          // this.isLoading = true;
          await this.getBranchList(field, field.branch_group_id);
          field.select_branch = field.select_branch || [];
        }
      }

      this.additionnalQuestion = data.detail.customField.sort(
        (a, b) => a.sort_order - b.sort_order
      );
      data.detail.profileField = data.detail.profileField.map((el) => {
        if (el.field_language.length == 0)
          return {
            ...el,
            field_language: [
              {
                id: 0,
                language_id: 1,
                field_id: el.id,
                name: el.name,
              },
            ],
          };
        else {
          return { ...el };
        }
      });
      this.questionList = data.detail.profileField
        .filter((el) => el.group_id == 1)
        .sort((a, b) => a.sort_order - b.sort_order);

      this.socialField = data.detail.socialField
        .map((el) => {
          return { ...el, is_social: true };
        })
        .sort((a, b) => a.sort_order - b.sort_order);
      this.addressList = data.detail.profileField
        .filter((el) => el.group_id == 2)
        .sort((a, b) => a.sort_order - b.sort_order);

      this.isLoading = false;
    },
    handleCheckRequired(val, items) {
      if (val) {
        this.$nextTick(
          () => (items.is_show = 1),
          (items.is_show = 1),
          (items.show_on_edit_profile = 1)
        );
      }
    },
    disabledField(items) {
      if (
        items.field_profile_type_id == 3 ||
        items.field_profile_type_id == 5 ||
        items.field_profile_type_id == 10 ||
        (this.id == 2 && items.field_profile_type_id == 27) ||
        items.field_profile_type_id == 28 ||
        items.field_profile_type_id == 29 ||
        items.field_profile_type_id == 22
      ) {
        return true;
      }
      if (this.id == 3 && items.field_profile_type_id == 11) return true;
      if (items.is_profile_required) {
        return true;
      }
      if (this.isRegister) {
        return true;
      }
      return false;
    },

    async switchProfileIndex(items, realIndex, val, activeIndex) {
      if (activeIndex == 1) return;
      let list = ["questionList", "addressList", "socialField"];

      let groupedList = [16, 17, 18];

      if (groupedList.includes(items.field_profile_type_id)) {
        let sortList = this[list[activeIndex]].splice(realIndex, 3);
        realIndex = val ? realIndex + 1 : realIndex - 1;

        this[list[activeIndex]].splice(
          realIndex,
          0,
          sortList[0],
          sortList[1],
          sortList[2]
        );
      } else {
        if (realIndex == 0 && !val) {
          return;
        }
        let sortIndex = 0;
        if (
          groupedList.includes(
            this[list[activeIndex]][val ? realIndex + 1 : realIndex - 1]
              .field_profile_type_id
          )
        ) {
          sortIndex = val ? realIndex + 3 : realIndex - 3;
        } else {
          sortIndex = val ? realIndex + 1 : realIndex - 1;
        }
        this[list[activeIndex]].splice(realIndex, 1);

        this[list[activeIndex]].splice(sortIndex, 0, items);
        var index = 0;
        for (const fields of this[list[activeIndex]]) {
          fields.sort_order = index;
          index++;
        }
      }
      this.$nextTick(() => {
        this[list[activeIndex]] = [...this[list[activeIndex]]];
        // this.sortArray();
      });
    },

    questionDetailText(index) {
      if (index == 0) {
        const text = this.questionList
          .filter((el) => el.is_show || el.show_on_edit_profile)
          .map((els) => els.name)
          .join(",");
        return text;
      }
      if (index == 1) {
        const text = this.addressList
          .filter((el) => el.is_show || el.show_on_edit_profile)
          .map((els) => els.name)
          .join(",");
        return text;
      }
      if (index == 2) {
        const text = this.socialField
          .filter((el) => el.is_show || el.show_on_edit_profile)
          .map((els) => els.name)
          .join(",");
        return text;
      }
    },
    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);

          return this.fieldTypes[index].name;
        }
    },
    addQuestion() {
      let lang = [];
      for (const { id } of this.$refs.inputLang.languageList) {
        lang.push({
          id: 0,
          language_id: id,
          name: "",
          description: "",
        });
      }

      this.additionnalQuestion.push({
        field_type_id: 1,
        required: false,
        img: "",
        name: "",
        is_show: 1,
        field_profile_type_id: 0,
        select_branch: [],
        field_choices: [],
        field_conditions: [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ],
        validate_text: this.defaultValidateText(1),
        field_language: lang,
        show_on_edit_profile: 0,
      });
      this.activeIndex = this.additionnalQuestion.length - 1;
      this.activeAddition = true;
    },
    async sendForm() {
      var nameList = ["นาย", "นาง", "นางสาว"];
      let clone = JSON.parse(JSON.stringify(this.additionnalQuestion));
      this.additionnalQuestion = await this.additionnalQuestion.filter(
        (el) => el.field_type_id != 14 && el.field_type_id != 13
      );

      for (const fields of this.questionList) {
        switch (fields.field_profile_type_id) {
          case 2:
            if (fields.field_choices.every((el) => el.is_display == 0)) {
              fields.field_choices.map((el) => {
                if (nameList.includes(el.name)) el.is_display = 1;
              });
            }

            break;
          case 9:
            fields.field_conditions.map((el) => {
              if (el.field_condition_type_id == 3) {
                el.value = el.value == "" ? "1753-01-01" : el.value;
              } else if (el.field_condition_type_id == 4) {
                el.value =
                  el.value == ""
                    ? this.$moment().format("YYYY-MM-DD")
                    : el.value;
              }
            });

          default:
            break;
        }
      }
      // console.log(this.additionnalQuestion);

      await this.$nextTick();
      this.$v.additionnalQuestion.$touch();
      if (this.$v.additionnalQuestion.$error) {
        return;
      }

      this.additionnalQuestion = clone;
      let payload = {
        custom_form: this.additionnalQuestion.map((el, index) => {
          return { ...el, sort_order: index };
        }),
        profile_form: [...this.questionList, ...this.addressList],
        social_form: this.socialField,
      };
      this.$bus.$emit("showLoading");
      let url = "/setting/updateregisterform";
      if (this.id == 2) url = "/setting/updatecompanyform";
      if (this.id == 3) url = "/setting/updateregisterform/email";
      const { data } = await this.axios.put(url, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.$router.push("/setting/personal");
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },
    typeHidden(type) {
      if (type == 9 || type == 10 || type == 11 || type == 13 || type == 14) {
        return true;
      } else {
        return false;
      }
    },
    handleClickActive(val, index) {
      this.activeAddition = val;
      this.activeIndex = index;
    },
    deleteQuestion() {
      this.additionnalQuestion.splice(this.activeIndex, 1);
      if (this.additionnalQuestion.length == 0) {
        this.activeAddition = 0;
        this.activeIndex = 0;
      } else {
        this.activeIndex = this.activeIndex;
      }
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
    },
    changeDrag(value) {
      if (this.activeAddition) this.activeIndex = value.newIndex;
    },
    async changeQeustionType(value) {
      if (value == 1) {
        this.additionnalQuestion[this.activeIndex].field_conditions = [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ];
        this.additionnalQuestion[this.activeIndex].field_choices = [];
      } else if (value >= 2 && value <= 4) {
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        this.additionnalQuestion[this.activeIndex].field_choices = [
          { name: "choice 1", sort_order: 0, isDefault: 0 },
        ];
      } else if (value == 5 || value == 7) {
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        this.additionnalQuestion[this.activeIndex].field_choices = [
          { name: "" },
        ];
      } else if (value == 6) {
        this.additionnalQuestion[this.activeIndex].field_choices = [];
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        let idBranch = 0;
        if (this.additionnalQuestion[this.activeIndex].branch_group_id) {
          idBranch = this.additionnalQuestion[this.activeIndex].branch_group_id;
        }
        // this.isLoading = true;
        await this.getBranchList(
          this.additionnalQuestion[this.activeIndex],
          idBranch
        );
      } else {
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        this.additionnalQuestion[this.activeIndex].field_choices = [];
      }
      if (value != 6) {
        delete this.additionnalQuestion[this.activeIndex].branchList;
      }
      if (this.typeHidden(value)) {
        this.additionnalQuestion[this.activeIndex].required = false;
        this.additionnalQuestion[this.activeIndex].name = "";
      }
      this.additionnalQuestion[this.activeIndex].validate_text =
        this.defaultValidateText(value);
    },
    defaultValidateText(type) {
      if (type >= 2 && type <= 4) {
        return "Please select choice.";
      } else if (type == 5 || type == 7) {
        return "Please enter date.";
      } else if (type == 6) {
        return "Please select branch.";
      } else if (type == 8) {
        return "Please input image.";
      } else if (type == 10 || type == 11 || type == 13 || type == 14) {
        return "";
      } else {
        return "Please input text.";
      }
    },
    selectBranch(value) {
      this.additionnalQuestion[this.activeIndex].select_branch = value;
      this.$forceUpdate();
    },

    changeLanguage(val) {
      const form = [
        "questionList",
        "addressList",
        "socialField",
        "additionnalQuestion",
      ];
      var index = this[form[this.activeIndex]][0].field_language.findIndex(
        (el) => el.language_id == val
      );
      if (index == -1) {
        this.$bus.$emit("showLoading");
        for (const field of form) {
          for (const element of this[field]) {
            if (
              element.field_language.length !=
              this.$refs.inputLang.languageList.length
            ) {
              for (const { id } of this.$refs.inputLang.languageList) {
                let index = element.field_language.find(
                  (el) => el.language_id == id
                );
                if (!index)
                  element.field_language.push({
                    id: 0,
                    language_id: id,
                    field_id: element.field_profile_type_id,
                    name: "",
                  });
              }
            }
          }
        }

        index = this.questionList[0].field_language.findIndex(
          (el) => el.language_id == val
        );
        this.$bus.$emit("hideLoading");
      }
      this.languageIndex = index;
    },
    formHead() {
      switch (this.id) {
        case 2:
          return "Company Customer";

        case 3:
          return "Personal Customer (Email Login)";

        default:
          return "Personal Customer";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-normal {
  color: #5b5b5b;
}
.text-light-grey {
  color: #9a9a9a;
}

.header-title {
  height: 100%;
  padding: 8px 16px;
  margin-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-btn {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}
.add-btn:hover {
  background-color: var(--primary-color);
}
.question-panel {
  max-height: 50vh;
  background-color: white;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

.question-card {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);

  border-left: 5px solid transparent;
  background-color: white;
  border-radius: 4px;
  text-overflow: ellipsis;
}
.question-card.active {
  border-left: 5px solid var(--primary-color);
}
.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--theme-secondary-color);
  border-radius: 6px;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}
#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}
.margin-input {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.margin-input-5 {
  margin-bottom: 5px !important;
}
::v-deep .question-name-input.error {
  input {
    border: 1px solid red;
  }
}
.dropdown-input-field ::v-deep button {
  overflow: hidden;
  color: var(--font-main-color);
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  text-align: left;
  padding: 0.375rem 0.75rem;
  background: white;
}
::v-deep .multiselect__single {
  font-size: 14px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
